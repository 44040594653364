<template>
  <Title pageTitle="SHOPPING" pageDiscrption="ショッピングガイド" />
  <div class="container">
    <div class="shopping-menu container-narrow">
      <a href="#payment" class="shopping-menu__item">お支払いについて</a>
      <a href="#return" class="shopping-menu__item">商品の返品・交換について</a>
      <a href="#shipping" class="shopping-menu__item">商品の配送について</a>
      <a href="#date" class="shopping-menu__item">納期について</a>
    </div>
    <div class="shopping-guide container-narrow">
      <div id="payment" class="anchor mb-lg">
        <h3 class="heading-3">お支払いについて</h3>
        <p class="para">当店のお支払いには以下をご利用いただけます。</p>
        <div class="payment-type mb-sm">
          <div class="payment-type__item">クレジットカード決済（クロネコwebコレクト）</div>
          <div class="payment-type__item">銀行振込（住信SBIネット銀行・三井住友銀行・ゆうちょ銀行）</div>
          <div class="payment-type__item">代金引換</div>
        </div>

        <h4 class="heading-4">クレジットカード決済について</h4>
        <p>
          当店ではクレジットカードの決済について、ヤマトフィナンシャル株式会社のネット総合決済サービス「クロネコwebコレクト」を使用しております。幅広いクレジットカードに対応し、最新のセキュリティを標準装備しておりますので安心してご利用いただけます。（VISA・Master・JCB・アメリカンエキスプレス・ダイナースのマークが入っているカードは全てご利用いただけます。）
        </p>
        <div class="credit mb-sm">
          <h4 class="credit__title">ご利用可能クレジットカード</h4>
          <div class="credit__icon">
            <img src="../assets/img/icon_visa.gif" alt="" />
            <img src="../assets/img/icon_master.gif" alt="" />
            <img src="../assets/img/icon_JCB.gif" alt="" />
            <img src="../assets/img/icon_AMEX.gif" alt="" />
            <img src="../assets/img/icon_DC.png" alt="" />
          </div>
        </div>
        <h4 class="heading-4">銀行振込について</h4>
        <p class="para mb-sm">
          ご注文後、ご請求金額及び入金先口座をご連絡させて頂きますので、お近くの銀行またはATMより当店指定口座へ10日以内にお振り込み下さい。入金確認後7日以内に商品の生産・発送とさせて頂きます。
        </p>
        <p class="cap">各種金融機関への振込手数料はお客様にご負担頂いております。</p>
        <p class="cap mb-sm">期日を過ぎてご入金の確認が出来ない場合はキャンセルとさせて頂きます。</p>
        <h4 class="heading-4">代金引換について</h4>
        <p class="para">
          商品と引換に代金を配送業者に預ける支払サービスです。代金引換額に応じて1件あたり下記のサービス手数料（税込）を負担いただいております。
        </p>
        <div class="fee mb-sm">
          <div class="fee__base">10,000円未満</div>
          <div class="fee__cost">330円</div>
          <div class="fee__base">10,000円以上～30,000円未満</div>
          <div class="fee__cost">440円</div>
          <div class="fee__base">30,000円以上～100,000円未満</div>
          <div class="fee__cost">660円</div>
        </div>
      </div>
      <div id="return" class="anchor mb-lg">
        <h3 class="heading-3">商品の返品・交換について</h3>
        <p>
          ご注文の商品が到着しましたら、7日以内に「商品にキズや不具合がないか」「ご注文内容と違いがないか」を必ずご確認ください。不良品や注文した商品と違うなど当店の不手際による場合は返品・交換とも速やかに対応させていただきます。
        </p>
        <p class="mb-sm">その他の場合、当店の商品は全てオーダーメイドにより製作しておりますので、返品・交換は基本的に不可とさせていただいております。</p>
        <h4 class="heading-4">Tシャツサイズ変形時の保証</h4>
        <p>
          当店のTシャツはオーダーメイド商品としてお客様のご希望のサイズで製作しております。
          そのため納品後14日以内にサイズが大きく変わってしまった場合は、当店保証規定により返品・交換・再製作の対応をさせていただきます。
        </p>
        <div class="warranty">
          <div class="warranty__title">＜保証規定＞</div>
          <ul>
            <li>商品の到着から「14日以内」にご連絡をいただいている商品</li>
            <li>
              洗濯乾燥後にシワを伸ばしてヨレや歪みの無い状態から当店の採寸基準で計測していただき、納品時のサイズから下記条件の変形が認められる商品<br />
              <div class="conditions">着丈70cm以下のTシャツで2cm以上の変形が認められる場合</div>
              <div class="conditions">着丈70cm以上のTシャツで3cm以上の変形が認められる場合</div>
            </li>
            <li>Tシャツ記載の洗濯表示以外での取り扱いをしていない商品（乾燥機の使用や漂白剤など添加剤の使用、適正温度以外でのアイロン使用による変形は保証外となります）</li>
            <li>故意に生地を伸ばしたなど無理な力が加わっていない商品</li>
          </ul>
        </div>
        <p class="mb-sm">
          保証の適用は商品を当店で確認させていただき、上記条件に該当するかの判断をさせていただく必要がございます。保証の対象と認められた商品はお客様との協議により返品・交換・再製作のいずれかの対応をさせていただきます。
        </p>
        <h4 class="heading-4">返品にかかる送料について</h4>
        <p>不良品や当店の不手際による場合は大変ご迷惑をお掛け致しますが、送料・手数料等全て当店負担にて返品・交換対応させていただきます。</p>
        <p>
          サイズ変形が起こってしまった場合は14日以内にご連絡をいただき、送料着払いにて商品をお送りいただけますようお願い致します。商品を確認させていただいた後、保証適用と判断された場合の再送料は当店で負担致します。
        </p>
        <p>保証規定を満たしておらず保証適用と判断されなかった場合のお送りいただいた商品の返送料はお客様負担とさせていただきますので、送料着払いで当店から返送させていただきます。</p>
      </div>
      <div id="shipping" class="anchor mb-lg">
        <h3 class="heading-3">商品の配送について</h3>
        <p>お買い上げ金額の合計が20,000円以上のお客様は全国送料無料とさせていただきます。お買い上げ金額の合計が20,000円未満の場合は以下の配送料となります。</p>
        <div class="fee">
          <div class="fee__base">東京、神奈川、千葉、埼玉、茨城、栃木、群馬、山梨、新潟、長野、宮城、山形、福島、富山、石川、福井、静岡、愛知、三重、岐阜</div>
          <div class="fee__cost">770円</div>
          <div class="fee__base">大阪、京都、滋賀、奈良、和歌山、兵庫、青森、秋田、岩手</div>
          <div class="fee__cost">880円</div>
          <div class="fee__base">岡山、広島、山口、鳥取、島根、香川、徳島、愛媛、高知</div>
          <div class="fee__cost">1,155円</div>
          <div class="fee__base">福岡、佐賀、長崎、大分、宮崎、鹿児島、北海道</div>
          <div class="fee__cost">1,430円</div>
          <div class="fee__base">沖縄、離島（長崎・鹿児島など一部）</div>
          <div class="fee__cost">1,914円</div>
        </div>
        <p class="cap mb-sm">上記は全て税込価格です。サンプル商品、資料請求、生地見本などの送料は基本的に当店で負担いたします。また離島など一部地域で配送困難な場合はご連絡させて頂きます。</p>
      </div>
      <div id="date" class="anchor">
        <h3 class="heading-3">納期について</h3>
        <p class="mb-sm">商品はご注文から通常一週間以内に発送させていただきます。</p>
        <h4 class="heading-4">初回テイラーTシャツ</h4>
        <p>
          ご注文後に決済の確認が取れましたら一週間以内にサンプルTシャツを発送いたします。2回目以降のサンプルの発送は、チェックフォーム返信後一週間以内にさせていただきます。本商品の発送は、サイズ確定後一週間以内に発送させていただきます。
        </p>
        <p class="cap">多忙期や注文が集中した場合は発送までにお時間をいただく場合がございますが、その際は改めて発送日時をご連絡させていただきます。</p>
        <p class="cap mb-sm">お支払い方法で銀行振込をお選びいただいたお客様は、ご入金確認後一週間以内に発送させていただきます。</p>
        <h4 class="heading-4">生地見本付きリーフレット</h4>
        <p>リーフレットをご請求いただきましたお客様は、数日内に普通郵便にて発送いたします。ポスト投函となりますので10日以上経っても届かない場合は、お手数ですがご連絡ください。</p>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'
export default {
  components: {
    Title,
  },
}
</script>

<style lang="scss" scoped>
.anchor {
  padding-top: 70px;
  margin-top: -70px;
}
.shopping-menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;

  &__item {
    text-align: center;
    font-weight: 300;
    padding: 10px 0;
    border: 1px solid var(--color-dark);
    border-radius: 100px;
    background-color: transparent;
    color: var(--color-light);
    transition: border 0.3s ease-out;

    & a {
      width: 100%;
      height: 100%;
    }

    &:hover {
      border: 1px solid var(--color-light);
    }
  }
}
.shopping-guide {
  padding: 8rem 0 0;
}
.warranty {
  background-color: #222;
  padding: 3rem 2rem 2rem;
  margin: 30px 0;
  border: 1px solid var(--color-dark);
  border-radius: 10px;

  &__title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  & ul {
    padding-left: 2rem;

    & li {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 15px;
      line-height: 1.7;

      .conditions {
        font-size: 14px;
        font-weight: 300;
        padding: 10px 0 0;
        border-bottom: 1px solid var(--color-dark);
        display: inline-block;
      }
    }
  }
}

.payment-type {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    display: grid;
    place-items: center;
    background-color: #252525;
    height: 50px;
    width: 100%;
    margin-top: 5px;
    padding: 0 1.5rem;
  }
}

.credit {
  border: 1px solid var(--color-dark);
  padding: 1.5rem 2rem 2rem;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #222;

  &__title {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 15px;
  }
  &__icon {
    display: flex;

    & img {
      height: 3.5rem;
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}

.fee {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;

  &__base {
    grid-column: 1 / 4;
    background-color: #252525;
    display: grid;
    place-items: center;
    font-size: 13px;
    font-weight: 300;
    padding: 10px 1.5rem;
  }
  &__cost {
    grid-column: 4 / 5;
    background-color: #252525;
    display: grid;
    place-items: center;
    font-size: 13px;
    font-weight: 300;
    padding: 15px 1.5rem;
  }
}

#shipping .fee {
  margin-bottom: 10px;

  &__base {
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
